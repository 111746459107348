'use strict';
const ZootoolsEmailSpellChecker = require('@zootools/email-spell-checker');
//Lodash is removed from codebase. If we need to use this functionality need to implement lodash api with plain JS
/*var _ = require('lodash');
*/
var util = {
    /**
     * @function
     * @description appends the parameter with the given name and value to the given url and returns the changed url
     * @param {String} url the url to which the parameter will be added
     * @param {String} name the name of the parameter
     * @param {String} value the value of the parameter
     */
    appendParamToURL: function (url, name, value) {
        // quit if the param already exists
        if (url.indexOf(name + '=') !== -1) {
            return url;
        }
        var separator = url.indexOf('?') !== -1 ? '&' : '?';
        return url + separator + name + '=' + encodeURIComponent(value);
    },

    /**
     * @function
     * @description remove the parameter and its value from the given url and returns the changed url
     * @param {String} url the url from which the parameter will be removed
     * @param {String} name the name of parameter that will be removed from url
     */
    removeParamFromURL: function (url, name) {
        if (url.indexOf('?') === -1 || url.indexOf(name + '=') === -1) {
            return url;
        }
        var hash;
        var params;
        var domain = url.split('?')[0];
        var paramUrl = url.split('?')[1];
        var newParams = [];
        // if there is a hash at the end, store the hash
        if (paramUrl.indexOf('#') > -1) {
            hash = paramUrl.split('#')[1] || '';
            paramUrl = paramUrl.split('#')[0];
        }
        params = paramUrl.split('&');
        for (var i = 0; i < params.length; i++) {
            // put back param to newParams array if it is not the one to be removed
            if (params[i].split('=')[0] !== name) {
                newParams.push(params[i]);
            }
        }
        return domain + '?' + newParams.join('&') + (hash ? '#' + hash : '');
    },

    /**
     * @function
     * @description appends the parameters to the given url and returns the changed url
     * @param {String} url the url to which the parameters will be added
     * @param {Object} params
     */
    appendParamsToUrl: function (url, params) {
        var _url = url;
		Object.keys(params).forEach(function(item){
			_url = this.appendParamToURL(_url, item, params[""+item+""])
		},this)
		/*params.map(function(item){
			_url = this.appendParamToURL(_url, item.key, value);
		}).bind(this)
        _.each(params, function (value, name) {
            _url = this.appendParamToURL(_url, name, value);
        }.bind(this));*/
        return _url;
    },
    /**
     * @function
     * @description extract the query string from URL
     * @param {String} url the url to extra query string from
     **/
    getQueryString: function (url) {
        var qs;
        if (!this.isString(url)) { return; }
        var a = document.createElement('a');
        a.href = url;
        if (a.search) {
            qs = a.search.substr(1); // remove the leading ?
        }
        return qs;
    },
    /**
     * @function
     * @description
     * @param {String}
     * @param {String}
     */
    elementInViewport: function (el, offsetToTop) {
        var top = el.offsetTop,
            left = el.offsetLeft,
            width = el.offsetWidth,
            height = el.offsetHeight;

        while (el.offsetParent) {
            el = el.offsetParent;
            top += el.offsetTop;
            left += el.offsetLeft;
        }

        if (typeof(offsetToTop) !== 'undefined') {
            top -= offsetToTop;
        }

        if (window.pageXOffset !== null) {
            return (
                top < (window.pageYOffset + window.innerHeight) &&
                left < (window.pageXOffset + window.innerWidth) &&
                (top + height) > window.pageYOffset &&
                (left + width) > window.pageXOffset
            );
        }

        if (document.compatMode === 'CSS1Compat') {
            return (
                top < (window.document.documentElement.scrollTop + window.document.documentElement.clientHeight) &&
                left < (window.document.documentElement.scrollLeft + window.document.documentElement.clientWidth) &&
                (top + height) > window.document.documentElement.scrollTop &&
                (left + width) > window.document.documentElement.scrollLeft
            );
        }
    },

    /**
     * @function
     * @description Appends the parameter 'format=ajax' to a given path
     * @param {String} path the relative path
     */
    ajaxUrl: function (path) {
        return this.appendParamToURL(path, 'format', 'ajax');
    },

    /**
     * @function
     * @description
     * @param {String} url
     */
    toAbsoluteUrl: function (url) {
        if (url.indexOf('http') !== 0 && url.charAt(0) !== '/') {
            url = '/' + url;
        }
        return url;
    },
    /**
     * @function
     * @description Loads css dynamically from given urls
     * @param {Array} urls Array of urls from which css will be dynamically loaded.
     */
    loadDynamicCss: function (urls) {
        var i, len = urls.length;
        for (i = 0; i < len; i++) {
            this.loadedCssFiles.push(this.loadCssFile(urls[i]));
        }
    },

    /**
     * @function
     * @description Loads css file dynamically from given url
     * @param {String} url The url from which css file will be dynamically loaded.
     */
    loadCssFile: function (url) {
        return $('<link/>').appendTo($('head')).attr({
            type: 'text/css',
            rel: 'stylesheet'
        }).attr('href', url); // for i.e. <9, href must be added after link has been appended to head
    },
    // array to keep track of the dynamically loaded CSS files
    loadedCssFiles: [],

    /**
     * @function
     * @description Loads js file dynamically from given url
     * @param {String} url The url from which js file will be dynamically loaded.
     */
    loadJsFile: function (url) {
        return $('<script/>').appendTo($('head')).attr({
            type: 'text/javascript',
            src: url
        });
    },

    /**
     * @function
     * @description Removes all css files which were dynamically loaded
     */
    clearDynamicCss: function () {
        var i = this.loadedCssFiles.length;
        while (0 > i--) {
            $(this.loadedCssFiles[i]).remove();
        }
        this.loadedCssFiles = [];
    },
    /**
     * @function
     * @description Extracts all parameters from a given query string into an object
     * @param {String} qs The query string from which the parameters will be extracted
     */
    getQueryStringParams: function (qs) {
        if (!qs || qs.length === 0) { return {}; }
        var params = {},
            unescapedQS = decodeURIComponent(qs);
        // Use the String::replace method to iterate over each
        // name-value pair in the string.
        unescapedQS.replace(new RegExp('([^?=&]+)(=([^&]*))?', 'g'),
            function ($0, $1, $2, $3) {
                params[$1] = $3;
            }
        );
        return params;
    },

    fillAddressFields: function (address, $form) {
        for (var field in address) {
            if (field === 'ID' || field === 'UUID' || field === 'key') {
                continue;
            }
            // if the key in address object ends with 'Code', remove that suffix
            // keys that ends with 'Code' are postalCode, stateCode and countryCode
            $form.find('[name$="' + field.replace('Code', '') + '"]').val(address[field]);
            // update the state fields
            if (field === 'countryCode') {
                $form.find('[name$="country"]');
                // retrigger state selection after country has changed
                // this results in duplication of the state code, but is a necessary evil
                // for now because sometimes countryCode comes after stateCode
                $form.find('[name$="state"]').val(address.stateCode);
                $form.find('[name$="state"]').attr('data-selectedValue', address.stateCode);
            }
        }
    },
    /**
     * @function
     * @description Updates the number of the remaining character
     * based on the character limit in a text area
     */
    limitCharacters: function () {
        $('form').find('textarea[data-character-limit]').each(function () {
            var characterLimit = $(this).data('character-limit');
            var charCountHtml = String.format(Resources.CHAR_LIMIT_MSG,
                '<span class="char-remain-count">' + characterLimit + '</span>',
                '<span class="char-allowed-count">' + characterLimit + '</span>');
            var charCountContainer = $(this).next('div.char-count');
            if (charCountContainer.length === 0) {
                charCountContainer = $('<div class="char-count"/>').insertAfter($(this));
            }
            charCountContainer.html(charCountHtml);
            // trigger the keydown event so that any existing character data is calculated
            $(this).change();
        });
    },
    /**
     * @function
     * @description Binds the onclick-event to a delete button on a given container,
     * which opens a confirmation box with a given message
     * @param {String} container The name of element to which the function will be bind
     * @param {String} message The message the will be shown upon a click
     */
    setDeleteConfirmation: function (container, message) {
        $(container).on('click', '.delete', function () {
            return window.confirm(message);
        });
    },
    /**
     * @function
     * @description Scrolls a browser window to a given x point
     * @param {String} The x coordinate
     */
    scrollBrowser: function (xLocation) {
        $('html, body').animate({scrollTop: xLocation}, 500);
    },

    isMobile: function () {
        var mobileAgentHash = ['mobile', 'tablet', 'phone', 'ipad', 'ipod', 'android', 'blackberry', 'windows ce', 'opera mini', 'palm'];
        var    idx = 0;
        var isMobile = false;
        var userAgent = (navigator.userAgent).toLowerCase();

        while (mobileAgentHash[idx] && !isMobile) {
            isMobile = (userAgent.indexOf(mobileAgentHash[idx]) >= 0);
            idx++;
        }
        return isMobile;
    },

	isString: function(element){
		return typeof(element) ==="string";
	},

    /**
     * @function
     * @description Get the unique list
     * @param list
     @returns {[]}
     */
    uniqueList: function (list){
        var result = [];
        $.each(list, function(i, e) {
            if ($.inArray(e, result) == -1) result.push(e);
        });
        return result;
    },

    formatUSPhoneNumber: function (phone) {
        var hasPlusSign = phone.substring(0, 2) === '+1',
            n = hasPlusSign ? phone.replace(/[()-/\s]/g,'') : phone.replace(/[+()-/\s]/g,''),
            plusSign= '';

            if (hasPlusSign || phone.charAt(0) === '1') {
                plusSign = '+1'
                n = n.charAt(0) === '1' ? n.substring(1, 11) : n.substring(2, 12)
            }
        if (n.length >= 3) {
            var dash = n.length > 6 ? '-' : '';
            phone = `${plusSign}(${n.substring(0, 3)})${n.substring(3, 6)}${dash}${n.substring(6, 10)}`;
        }
        return phone;
    },
    togglePasswordType: {
        clickEvent: function () {
            var _self = this;
            $(document).on('click', '[data-toggle-password]', function (e) {
                e.preventDefault();
                e.stopPropagation();
                e.stopImmediatePropagation();
                _self.action($(this));
            });
        },
        keypressEvent: function () {
            var _self = this;
            $(document).on('keypress', '[data-toggle-password]', function (e) {
                e.preventDefault();
                e.stopPropagation();
                e.stopImmediatePropagation();
                var key = e.which;
                if (key == 13)  // the enter key code
                {
                    _self.action($(this));
                }
            });
        },
        action: function (target) {
            var parent = target.closest('.form-group');
            var inputValue = parent.find('.form-control');
            target.find('.icon').toggleClass('d-none');
            if (inputValue.attr("type") == "password") {
                // target.find('svg').attr("data-icon", 'eye');
                inputValue.attr("type", "text");
                target.attr('aria-label','Hide Password');
            } else {
                // target.find('svg').attr("data-icon", 'eye-slash');
                inputValue.attr("type", "password");
                target.attr('aria-label','Show Password');
            }
        },
        init:function(){
            this.clickEvent();
            this.keypressEvent();
        }
    },
    showMoreLink: function () {
        $('[data-show-more="cta"]').click(function(e) {
            e.preventDefault();
            var start = Number($(this).attr('data-start'));
            var totalCount = start + Number($(this).attr('data-load'));
            for(i=start; i < totalCount; i++){
                $(`[data-show-more="item-${i}.0"]`).removeClass('d-none')
            }
            if(Number($(this).attr('data-length')) >= totalCount) {
                $(this).attr('data-start', totalCount+'.0')
            } else {
                $(this).addClass('d-none')
            }
        });
    },
    getAlertHtml: function (msg, iconID, iconClass, wrapperClass) {
        return `<div class="alert ${wrapperClass}" role="alert">` +
            `<svg aria-hidden="true" focusable="false" class="icon alert-icon  ${iconClass}">
                <use xlink:href="#${iconID}"></use>
            </svg>` + msg + '</div>';
    },
    getAlertInnerHtml: function (msg, iconID, iconClass) {
        return `<svg aria-hidden="true" focusable="false" class="icon ${iconClass}">
                <use xlink:href="#${iconID}"></use>
            </svg>` + msg ;
    },
    getCountryCode: function (){
        const queryString = window.location.search;
        var countryCode = 'US';
        if(queryString){
            const urlParams = new URLSearchParams(queryString);
            countryCode =  urlParams.get('country') ? urlParams.get('country') : 'US';
        }
        return countryCode;
    },
    emailSpellChecker: function(elem) {
        const emailSpellCheckerConfig = window.emailSpellCheckerConfig ? window.emailSpellCheckerConfig : {};
        const id = elem.attr('id');
        const suggestedEmail = ZootoolsEmailSpellChecker.run({
            email: elem.val(),
            domains: emailSpellCheckerConfig.domains ? emailSpellCheckerConfig.domains : [...ZootoolsEmailSpellChecker.POPULAR_DOMAINS],
            topLevelDomains: emailSpellCheckerConfig.topLevelDomains ? emailSpellCheckerConfig.topLevelDomains : [],
        });
        if(suggestedEmail) {
            const getButton = $(`#${id}-email-checker`);
            const msg = `Did you mean <span class="text-decoration-underline">${suggestedEmail.full}?</span>`;
            if(getButton.length) {
                getButton.attr('data-value', suggestedEmail.full).html(msg)
            } else {
                var html = `<button type="button" id="${id}-email-checker" data-target="${id}" class="help-email-spell-checker ignore text-danger btn-reset font-weight-300" data-email-spell-checker="button" data-value="${suggestedEmail.full}">${msg}</button>`;
                var parentElement = elem.closest('.form-group');
                parentElement.addClass('has-email-checker');
                if($('#checkout-main').length) {
                    parentElement.find('.float-label').after(html);
                } else {
                    elem.after(html);
                }
            }
        } else {
            util.clearEmailSpellChecker(elem.attr('id'));
        }
    },
    emailSpellCheckerClickEvent: function() {
        $(document).on('click','[data-email-spell-checker="button"]',function(e) {
            e.preventDefault();
            var id = $(this).attr('data-target');
            $(`#${id}`).val($(this).attr('data-value')).focus();
            util.clearEmailSpellChecker(id);
        });
    },
    clearEmailSpellChecker: function(id) {
        $(`#${id}-email-checker`).remove();
        $(`#${id}`).closest('.form-group').removeClass('has-email-checker');
    },
    talonEventTrigger: function (actionType) {
        if (window.tatcha && window.tatcha.mParticleTalon && window.tatcha.mParticleTalon.talonOneData && window.tatcha.mParticleTalon.actionType === actionType) {
            var mp_taloneOneTrackEvent = new CustomEvent("talonone:track_event", { "detail": window.tatcha.mParticleTalon.talonOneData });
            document.dispatchEvent(mp_taloneOneTrackEvent);
            window.tatcha.mParticleTalon = {
                talonOneData: null,
                actionType: null
            }
        }
    }
};

module.exports = util;
