'use strict';

var helper = require('../../helper');

var braintreeCreditCardModel;
var addNewCreditCardLoader;
var alertHandler;
var ccMessages;

var hfFieldsInstance;
var loaderInstance = require('base_braintree/braintree/loaderHelper');

/**
 * Closes js-braintree-add-new-card-block & clears hosted fields input data
 */
function closeAddNewCardBlock() {
    const $addNewCardBlock = document.querySelector('#add-creditcard-modal');
    // Clear hosted fields input data
    $addNewCardBlock.classList.add('d-none');
    hfFieldsInstance.clearHostedFields();
}

/**
 * Shows message if Credit Card was updated
 * @param {Object} data addCreditCard response
 */
function showCardUpdateMessage(data) {
    if (data.isCardNumberUpdated) {
        alertHandler.showInfo(data.cardNumberUpdatedMessage);
    }
}

/**
 * Stores new Credit Card on account page
 * @param {Object} event Event object
 * @returns {void}
 */
function storeNewCreditCard(event) {
    const creditCardAccountHelper = require('base_braintree/braintree/creditcard/helpers/creditCardAccount');
    const braintreeAccount = require('base_braintree/braintree/braintreeAccount');

    const $customCreditCardErrorContainer = document.getElementById('customCreditCardErrorContainer');
    const customErrorList = helper.tryParseJSON($customCreditCardErrorContainer.getAttribute('data-errors'));

    const isCheckoutPage = false;

    // Clears error messages on the top of the Account Page
    alertHandler.hideAlerts();

    try {
        if (!braintreeCreditCardModel) {
            throw ccMessages.CLIENT_MISSING_GATEWAY_CONFIGURATION;
        }

        addNewCreditCardLoader.show();

        const addCreditCardFormEl = document.querySelector('.js-braintree-add-credit-card-form');

        helper.clearForm(addCreditCardFormEl);

        hfFieldsInstance.tokenize(isCheckoutPage)
            .then(function(btModelPayload) {
                const formValidation = require('customBase/components/formValidation');

                const tokenizePayload = btModelPayload.btTokenizePayload;
                const addCreditCardFormUrl = addCreditCardFormEl.getAttribute('action');
                const isValidCardholderName = helper.validateCardholderName(btModelPayload, addCreditCardFormEl);

                if (!helper.validateForm(addCreditCardFormEl) || !isValidCardholderName) {
                    addNewCreditCardLoader.hide();
                    return Promise.reject(customErrorList.VALIDATION_INVALID); // eslint-disable-line no-undef
                }

                return helper.checkForDuplicatedCC(tokenizePayload)
                    .then(function(result) {
                        if (result.error) {
                            formValidation(addCreditCardFormEl, {error: result.message});
                            throw result;
                        }
                    })
                    .then(function() {
                        return creditCardAccountHelper.verifyCard3dSecure(addCreditCardFormEl, braintreeCreditCardModel, tokenizePayload);
                    })
                    .then(function() {
                        // Sets value of Credit Card hidden fields needed for server side
                        creditCardAccountHelper.setCardHiddenFields(tokenizePayload);

                        // Call to Braintree-AccountAddCreditCardHandle preparation
                        const formData = new FormData(addCreditCardFormEl);
                        const $braintreeCreditCardNonce = document.getElementById('braintreeCreditCardNonce');

                        fetch(`${addCreditCardFormUrl}?braintreePaymentMethodNonce=${$braintreeCreditCardNonce.value}`, {
                            method: 'POST',
                            body: formData
                        })
                            .then((response) => response.json())
                            .then((data) => {
                                if (!data.success) {
                                    addNewCreditCardLoader.hide();
                                    alertHandler.handleCreditCardError(data.success);
                                    delete data.success;
                                    formValidation(addCreditCardFormEl, data);
                                } else {
                                    closeAddNewCardBlock();
                                    addNewCreditCardLoader.hide();
                                    location.reload();
                                }
                            });
                    });
            })
            .catch(function(error) {
                addNewCreditCardLoader.hide();
                alertHandler.handleCreditCardError(error, hfFieldsInstance.getCcFields());

                helper.validateForm(addCreditCardFormEl);
            });

        event.preventDefault();
        event.stopPropagation();
    } catch (error) {
        addNewCreditCardLoader.hide();
        alertHandler.handleCreditCardError(error);
    }
}

/**
 * Inits Credit Card account loaders
 */
function initCreditCartAccountLoaders() {
    // const $braintreeCreditCardLoader = document.getElementById('braintreeCreditCardLoader');
    // const braintreeCreditCardLoaderExists = Boolean($braintreeCreditCardLoader);

    // if (braintreeCreditCardLoaderExists) {
    //     addNewCreditCardLoader = loaderInstance($braintreeCreditCardLoader);
    // }
    addNewCreditCardLoader = $('.loader-preventive')
}
function removeBtPayment(e) {
    let target = e.target;
    var $loaderContainter = document.querySelector('#' + target.getAttribute('data-loader'));
    var accountsLoader = loaderInstance($loaderContainter);
    accountsLoader.show();
    $.get(target.getAttribute('data-url') + '?UUID=' + target.getAttribute('data-id') +'&'+target.getAttribute('data-csrf-name')+"="+target.getAttribute('data-csrf-token'))
        .then((data => {
            $('#uuid-' + data.UUID).remove();
            if (data.newDefaultAccount) {
                document.querySelector('#uuid-' + data.newDefaultAccount + ' span').style.fontWeight = 'bold';
                document.querySelector('#uuid-' + data.newDefaultAccount + ' button.braintree-make-default-card').style.display = 'none';
            }
            accountsLoader.hide();
        }))
        .fail(() => {
            location.reload();
            accountsLoader.hide();
        });
}
function setDefaultProperty(params) {
    return $.get(params.url + '?UUID=' + params.id + '&pmID=' + params.paymentMethodID +'&'+params.tokenName +"=" + params.tokenValue)
        .then((data) => {
            location.reload();
            // document.querySelector('#uuid-' + data.newDefaultProperty + ' span').style.fontWeight = 'bold';
            // document.querySelector('.braintree-make-default-card.uuid-' + data.newDefaultProperty).style.display = 'none';
            // document.querySelector('#uuid-' + data.toRemoveDefaultProperty + ' span').style.fontWeight = 'normal';
            // document.querySelector('.braintree-make-default-card.uuid-' + data.toRemoveDefaultProperty).style.display = 'inline';
            // if(data && data.redirectUrl){
            //     location.reload();
            // }
            //params.loader.hide();
        })
        .fail(() => {
            params.loader.hide();
        });
}
/**
 * Init Credit Card Account functionality
 * @param {Object} btCreditCardModel Braintree Credit Card model
 * @param {Constructor} alertHandlerModel Alert handling model
 * @param {Object} creditCardMessages List of Credit Card error messages
 * @param {Object} hostedFieldsInstance A Hosted fields instance
 */
function init(btCreditCardModel, alertHandlerModel, creditCardMessages, hostedFieldsInstance) {
    ccMessages = creditCardMessages;
    alertHandler = alertHandlerModel;
    braintreeCreditCardModel = btCreditCardModel;
    hfFieldsInstance = hostedFieldsInstance;

    const addNewCreditCardFormExists = Boolean(document.querySelector('.js-braintree-add-credit-card-form'));

    initCreditCartAccountLoaders();

    if (addNewCreditCardFormExists) {
        document.querySelector('.js-braintree-add-new-card-save-btn').addEventListener('click', storeNewCreditCard);
        document.querySelector('.js-braintree-add-new-card-cancel-btn').addEventListener('click', closeAddNewCardBlock);
    }
    const cardManipulation = Boolean(document.querySelector('.creditCard-accounts'));

    if(cardManipulation) {
        document.querySelector('.creditCard-accounts').onclick = function (e) {
            var tokenName = e.target.getAttribute('data-csrf-name');
            var tokenValue = e.target.getAttribute('data-csrf-token');
            if (e.target.classList.contains('remove-bt-payment') && tokenName && tokenValue) {
                removeBtPayment(e);
            } else if (e.target.classList.contains('braintree-make-default-card') && tokenName && tokenValue) {
                var target = e.target;
                var url = target.getAttribute('data-make-default-url');
                var id = target.getAttribute('data-id');
                var paymentMethodID = 'CARD';
                var $loaderContainter = document.querySelector('#' + target.getAttribute('data-loader'));
                var loader = loaderInstance($loaderContainter);
                loader.show();
                setDefaultProperty({
                    url: url,
                    id: id,
                    paymentMethodID: paymentMethodID,
                    loader: loader,
                    tokenName: tokenName,
                    tokenValue: tokenValue
                });
            }
        };
    }
}

module.exports = {
    init,
    closeAddNewCardBlock
};
